<template>
  <div class="potential">
    <rxNavBar :title="navTitle"></rxNavBar>
    <!-- <p class="application_time">申请时间：<span>{{$route.query.reportingTime}}</span></p> -->
    <p class="subtitle">基本信息</p>
    <div class="panel">
      <!-- <div class="state">{{$route.query.approvalStatusStr}}</div> -->
      <van-row class="info">
        <van-col span="12">
          <p>
            状态: <span>{{ baseForm.approvalStatusStr }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            姓名: <span>{{ baseForm.name }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            手机号: <span>{{ baseForm.phoneNum }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            性别: <span>{{ baseForm.gender }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            试岗部门：<span>{{ baseForm.departmentName }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            职务：<span>{{ baseForm.dutyName }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            推荐人：<span>{{ baseForm.staffName }}</span>
          </p>
        </van-col>
        <van-col span="12" v-if="baseForm.approvalStatusStr==='待审批'">
          <p>
            上级：<span>{{ baseForm.seniorStaffName }}</span>
          </p>
        </van-col>
        <van-col span="24">
          <p>
            证件类型：<span>{{ baseForm.staffCertificateType }}</span>
          </p>
        </van-col>

        <van-col span="24">
          <p>
            证件号: <span>{{ baseForm.idCard }}</span>
          </p>
        </van-col>
        <van-col span="24" v-if="baseForm.approvalStatusStr === '已撤销'">
          <p>
            撤销原因: <span>{{ baseForm.remarks }}</span>
          </p>
        </van-col>
      </van-row>
    </div>

    <div v-if="baseForm.approvalStatusStr==='已通过'||baseForm.approvalStatusStr==='已拒绝'">
      <p class="subtitle">审批信息</p>
      <div class="panel">
        <van-row class="info">
          <van-col span="12">
            <p>
              发起人: <span>{{ baseForm.starStaffName }}</span>
            </p>
          </van-col>
          <van-col span="12">
            <p>
              审批人: <span>{{ baseForm.approvalStaffName }}</span>
            </p>
          </van-col>
          <van-col span="12">
            <p>
              审批结果：<span>{{ baseForm.approvalResultStatus }}</span>
            </p>
          </van-col>
          <van-col
            span="12"
            v-if="baseForm.approvalResultStatus === '审批拒绝'"
          >
            <p>
              拒绝原因：<span>{{ baseForm.refuseReason }}</span>
            </p>
          </van-col>
          <van-col span="24">
            <p>
              发起时间: <span>{{ baseForm.starApprovalTime }}</span>
            </p>
          </van-col>

          <van-col span="24">
            <p>
              审批时间: <span>{{ baseForm.approvalTime }}</span>
            </p>
          </van-col>
          <van-col span="24">
            <p>
              审批意见：<span>{{ baseForm.approvalOpinion }}</span>
            </p>
          </van-col>
        </van-row>
      </div>
    </div>

    <div v-if="baseForm.approvalResultStatus==='审批同意'">
      <p class="subtitle">详细信息</p>
      <div class="panel">
        <van-row class="info">
          <van-col span="12">
            <p>
              试岗日期: <span>{{ baseForm.entryTime }}</span>
            </p>
          </van-col>
          <van-col span="12">
            <p>
              职务级别: <span>{{ baseForm.dutyLevelName }}</span>
            </p>
          </van-col>
          <van-col span="24">
            <p>
              紧急联系人关系：<span>{{
                baseForm.emergencyContactRelationship
              }}</span>
            </p>
          </van-col>
          <van-col span="24">
            <p>
              紧急联系人: <span>{{ baseForm.emergencyContactPerson }}</span>
            </p>
          </van-col>
          <van-col span="24">
            <p>
              紧急联系人电话：<span>{{ baseForm.emergencyContactPhone }}</span>
            </p>
          </van-col>
          <van-col span="24">
            <p>
              入职公司: <span>{{ baseForm.companyName }}</span>
            </p>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>
<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {
  NavBar,
  Tab,
  Tabs,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Tag,
  Button,
  Popup,
  Field,
  Divider,
  Image as VanImage,
  ImagePreview,
  Rate,
  DatetimePicker,
  Picker,
  Toast,
} from "vant";
import { browseInterpolateApprovalDetail } from "../../../getData/getData";
import {
  getStaffId,
  responseUtil,
  globaluserId,
} from "../../../libs/rongxunUtil";
export default {
  data() {
    return {
      //头部标题
      navTitle: "新人试岗申请审批详情",
      baseForm: {},
      formValidate: {
        // approveResult: 0,
        refuseReason_id: "",
        approvalOpinion: "",

        registeredResidenceAddress: "",
        entryTime: "",
        dutyLevel_id: "",
        // accountinfo_id: "",
        emergencyContactPerson: "",
        emergencyContactPhone: "",
        emergencyContactRelationship: "",
      },
      routeData: {},
    };
  },
  name: "ReviewOfDecorationScheme",
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Divider.name]: Divider,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [DatetimePicker.name]: DatetimePicker,
    [Rate.name]: Rate,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
    rxNavBar,
  },
  activated() {
    this.routeData = this.$route.query;
    this.navTitle = "新人试岗审批";
    this.getDetail();
  },
  methods: {
    //提报详情
    getDetail() {
      let that = this;
      let initData = {};
      initData.id = that.routeData.id;
      browseInterpolateApprovalDetail(initData).then((res) => {
        console.log(res);
        that.baseForm = res.data.data.data;
        that.baseForm.seniorStaffName = that.routeData.seniorStaffName
      });
    },
  },
};
</script>
<style lang="less" scoped>
@redius: 8px;
@border: 1px solid rgb(244, 244, 244);
.potential {
  width: 375px;
  background-color: rgb(250, 250, 250);
  padding: 0 15px;
  box-sizing: border-box;
  overflow: hidden;
  color: #252525;

  .sub_btn {
    margin: 30px 0 70px;

    .sub_button {
      border-radius: @redius;
      color: rgb(255, 255, 255);
      border: none;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(
        left,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );

      &.not_choose {
        background: none;
        background-color: rgba(184, 184, 184, 0.2);
      }
    }
  }

  p {
    margin: 0px;
  }

  .application_time {
    text-align: right;
    width: 345px;
    font-size: 12px;
    color: rgb(136, 136, 136);
    padding: 5px 0 5px;
  }

  .subtitle {
    padding: 5px 0px;
    color: rgb(244, 99, 76);
    margin-top: 15px;
    font-weight: 700;
    font-size: 12px;
  }

  .panel {
    width: 345px;
    background-color: rgb(255, 255, 255);
    border-radius: @redius;
    position: relative;
    font-size: 14px;
    text-align: left;
    overflow: hidden;

    &.withMargin {
      margin: 16px 0;
    }

    & > p {
      padding: 15px 15px 0px;
      font-weight: bolder;
      /*color: #252525;*/
    }

    & > p > span {
      font-weight: normal;
      color: rgb(190, 190, 190);
    }

    .state {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      font-weight: bold;
      height: 32px;
      line-height: 32px;
      width: 60px;
      display: block;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(
        left,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: rgb(255, 255, 255);
      text-align: center;
      transform: scale(0.5);
      transform-origin: 0% 0%;
      font-family: sans-serif;
      padding: 0 10px;
    }

    .info {
      padding: 15px;
      line-height: 16px;

      &.noPadding {
        padding: 0 15px;
      }

      p {
        margin: 7px 0;
        font-weight: bolder;

        span {
          font-size: 13px;
          font-weight: normal;
          color: rgb(110, 110, 110);
        }
      }
    }
  }
}
</style>
